import React, { useEffect } from 'react';
import "./App.css";

function App() {

  function handleOrientation(event) {
    const card = document.getElementsByClassName('card')[0];
    try {
      const alpha = event.alpha !== null ? event.alpha.toFixed(2) : 'N/A';
      const beta = event.beta !== null ? event.beta.toFixed(2) : 'N/A';
      const gamma = event.gamma !== null ? event.gamma.toFixed(2) : 'N/A';
      
      // Apply skewY transformation only if beta is between -5 and 5 degrees
      if (beta <= 5 && beta >= -5) {
        card.style.transform = `skewY(${beta}deg)`;
      }
      
      document.getElementById('visible').innerText = `Alpha: ${alpha}, Beta: ${beta}, Gamma: ${gamma}`;
    } catch (error) {
      console.error('Error handling orientation event:', error);
      document.getElementById('visible').innerText = 'Error handling orientation event.';
    }
  }

  function requestPermission() {
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            window.addEventListener('deviceorientation', handleOrientation);
            document.getElementById('visible').innerText = 'Gyroscope enabled. Move your device!';
            document.getElementById('enableGyro').style.display="none";
          } else {
            console.error('Permission denied.');
            document.getElementById('visible').innerText = 'Permission denied for DeviceOrientationEvent.';
          }
        })
        .catch(error => {
          console.error('Error requesting permission:', error);
          document.getElementById('visible').innerText = 'Error requesting permission.';
        });
    } else {
      // For non-iOS 13+ devices
      window.addEventListener('deviceorientation', handleOrientation);
      document.getElementById('visible').innerText = 'Gyroscope enabled. Move your device!';
    }
  }

  useEffect(() => {
    const enableGyroButton = document.getElementById('enableGyro');
    enableGyroButton.addEventListener('click', requestPermission);

    return () => {
      enableGyroButton.removeEventListener('click', requestPermission);
    };
  }, []);

  return (
    <div className="app">
      <p id="visible"></p>
      <button id="enableGyro">Enable Gyroscope</button>
      <div className="card">
        <img src={require("./image.jpg")} alt="Door to Green!" width="100%" />
        <h5>Door to Green!</h5>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries,{" "}
        </p>
      </div>
    </div>
  );
}

export default App;
